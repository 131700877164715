<template lang="pug">
.category-popup
  modal(
    v-model="valueComputed"
    :primary-button="false"
    :secondary-button="false"
    :border="false"
    :padding="isDesktop"
    position="top"
    size="large"
  )
    template(v-slot:title) Productcategorie&euml;n
    category-wrapper(slot="body" :data="categories" @action="closeCategoryPopup")
</template>

<script>
import { breakpointMixin } from '@/mixins/breakpointMixin';

export default {
  name: 'CategoryPopup',
  components: {
    modal: () => import('@/components/modal'),
    'category-wrapper': () => import('@/components/category/categoryWrapper'),
  },
  mixins: [breakpointMixin],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    valueComputed: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    categories() {
      return this.$store.getters['api/mainNavigation'].map((category) => ({
        ...category,
        path: `/${category.path}`,
        conversions: category.media_conversions?.['tile-images'] || {},
      }));
    },
  },
  methods: {
    closeCategoryPopup() {
      this.$store.dispatch('shared/toggleCategoryPopup', false);
    },
  },
};
</script>

<style lang="scss">
.category-popup {
  .category-wrapper {
    width: 100%;
    margin: 0;
  }
}
@media screen and (max-width: $breakpoint-sm) {
  .category-popup {
    .category--card {
      padding: 3px;

      &:nth-child(3n+3) {
        padding-right: 0;
      }

      &:nth-child(3n+1) {
        padding-left: 0;
      }
    }
  }
}
@media screen and (max-width: $breakpoint-xs) {
  .category-popup {
    .category--card {
      padding: 3px;

      &:nth-child(3n+3) {
        padding-right: 3px;
      }

      &:nth-child(3n+1) {
        padding-left: 3px;
      }

      &:nth-child(2n+2) {
        padding-right: 0px;
      }

      &:nth-child(2n+1) {
        padding-left: 0px;
      }
    }
  }
}
</style>
